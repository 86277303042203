import { useMsal } from '@azure/msal-react';
import defaultAxios from 'axios'
import React, { useEffect } from 'react'
import { getToken } from '../authConfig';

type AxiosRequestInterceptor = Parameters<typeof defaultAxios.interceptors.request.use>[0]
type AxiosRequestErrorInterceptor = Parameters<typeof defaultAxios.interceptors.request.use>[1]

const axios = defaultAxios.create()

type AxiosInterceptorProps = {
    children?: React.ReactNode;
}

function AxiosInterceptor({ children }: AxiosInterceptorProps) {

    const { instance } = useMsal();

    useEffect(() => {
        const reqestInterceptor: AxiosRequestInterceptor = async (request) => {
            const params = new Proxy(new URLSearchParams(window.location.search), {
                // @ts-ignore
                get: (searchParams, prop) => searchParams.get(prop)
            });
            // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
            // @ts-ignore
            let HID_credentials = params.HID; // "some_value"
            // @ts-ignore
            let HID_tenant_id = params.tenant_id; // "some_value"

            if (request.url?.startsWith('https://management.azure.com')) {
                const token = await getToken(instance, {
                    scopes: ["https://management.azure.com/user_impersonation"],
                })

                request.headers['Authorization'] = `Bearer ${token?.accessToken}`

                return request;
            } else if (request.url?.startsWith('https://graph.microsoft.com')) {
                const activeAccount = instance.getActiveAccount();

                if (!activeAccount) {
                    console.error('No active account found')
                    return request;
                }

                const token = await getToken(instance, {
                    scopes: ["user.read"],
                })

                request.headers['Authorization'] = `Bearer ${token?.accessToken}`

                return request;
            } else {
                const token = await getToken(instance)
                request.headers['Authorization'] = `Bearer ${token?.accessToken}`
                request.headers['x-HID-key'] = HID_credentials
                request.headers['x-HID-tenant-id'] = HID_tenant_id
                return request
            }
        }

        const errorInterceptor: AxiosRequestErrorInterceptor = error => {
            console.error('errorInterceptor', error);
            return Promise.reject(error);
        }

        const interceptor = axios.interceptors.request.use(reqestInterceptor, errorInterceptor);

        return () => axios.interceptors.request.eject(interceptor);
    }, [])

    useEffect(() => {
        const responseInterceptor = axios.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                console.error('responseInterceptor', error);
                if (error.response?.status === 401) {
                    instance.logoutPopup();
                }
                return Promise.reject(error);
            }
        )

        return () => axios.interceptors.response.eject(responseInterceptor);
    }, [])

    return children;
}

export default axios
export { AxiosInterceptor }