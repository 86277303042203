import {
    Caption1,
    Card,
    CardHeader,
    shorthands,
    Text,
    makeStyles,
    cardHeaderClassNames,
    tokens,
    useId,
    Button,
    Popover,
    PopoverTrigger,
    PopoverSurface,
    Tooltip
} from '@fluentui/react-components';
import { stringToBrightColor } from '../../helpers/string-to-bright-color';
import { KeyboardEventHandler, useState, MouseEvent } from 'react';
import { CheckboxUnchecked20Regular, CheckboxChecked20Regular } from '@fluentui/react-icons';
// import styles from './knowledge-base-selector.module.scss';

const useStyles = makeStyles({
    card: {
        width: '100%',
        height: "fit-content",

        [`& > .${cardHeaderClassNames.root}`]: {
            flexShrink: 1,
        },
    },

    active: {
        backgroundColor: '#dee5ff',
    },

    header: {
        flexShrink: 1,
        minWidth: '0px',

        [`& > .${cardHeaderClassNames.description}`]: {
            ...shorthands.overflow("hidden"),
            minWidth: '0px',
        },
        [`& > .${cardHeaderClassNames.header}`]: {
            flexShrink: 1,
        },
    },

    title: {
        width: "90%",
    },

    preview: {
        flexShrink: 0,
        maxWidth: "64px",
        maxHeight: "64px"
    },

    caption: {
        color: tokens.colorNeutralForeground3,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        ...shorthands.overflow("hidden"),
    },

    publishedIcon: {
        position: 'absolute',
        top: '10px',
        right: '8px',
    },

    publishedIconUnchecked: {
        position: 'absolute',
        top: '10px',
        right: '8px',
        color: 'grey',
    },

    cardFooter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        ...shorthands.gap("4px"),
    },
    cardFooterItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        ...shorthands.gap("4px"),
    },
    cardPopover: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: '8px',
        padding: '4px',
    },
    popoverSurface: {
        border: '1px solid lightgrey',
        borderRadius: '10px',
    },
});

type KnowledgeBaseCardSelectableProps = {
    id: string;
    title: string;
    subtitle: string;
    selected: boolean;
    onClick?: () => void;
    documentCount: number;
    updatedAt: string;
    isFooterVisible: boolean;
    published: boolean;
    citation_view: boolean;
    canCreateKnowledgeBase: boolean;
    canCreateQuestionExamples: boolean;
}

export const KnowledgeBaseCardSelectable = ({
    id,
    title,
    subtitle,
    selected,
    onClick,
    documentCount,
    updatedAt,
    isFooterVisible,
    published,
    citation_view,
    canCreateKnowledgeBase,
    canCreateQuestionExamples
}: KnowledgeBaseCardSelectableProps) => {
    const labelId = useId('knowledge-base-card-label');
    const descriptionId = useId('knowledge-base-card-description');
    const styles = useStyles();

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const handleAuxClick = (event: MouseEvent<HTMLDivElement>) => {
        if (event.button === 1) { // Middle mouse button
            event.preventDefault();
            setIsPopoverOpen(true);
        }
    };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onClick?.();
        }
    };

    return (
        <Card
            appearance='filled-alternative'
            aria-selected={selected}
            className={`${selected ? styles.active : ''} ${styles.card}`}
            onClick={onClick}
            // onAuxClick={handleAuxClick}
            onKeyDown={handleKeyDown}
            aria-labelledby={labelId}
            aria-describedby={descriptionId}
            role={'button'}
        >
            <CardHeader
                image={
                    <img
                        src={
                            `https://ui-avatars.com/api?name=${title}&background=${stringToBrightColor(id)}`
                        }
                        alt={`${title} knowledge base logo`}
                        height={48}
                        width={48}
                    />
                }
                className={styles.header}
                header={<Text id={labelId} weight="semibold" className={styles.title}>{title}</Text>}
                description={
                    <Caption1 id={descriptionId} className={styles.caption}>{subtitle}</Caption1>
                }
            />
            {canCreateKnowledgeBase || canCreateQuestionExamples && (
                published ? (
                    <Tooltip content="Published" positioning={"after-top"} relationship="label">
                        <CheckboxChecked20Regular className={styles.publishedIcon} title="Published" aria-label="Published knowledge base." />
                    </Tooltip>
                ) : (
                    <Tooltip content="Unpublished" positioning={"after-top"} relationship="label">
                        <CheckboxUnchecked20Regular className={styles.publishedIconUnchecked} title="Not Published" aria-label="Unpublished knowledge base." />
                    </Tooltip>
                )
            )}
            {isFooterVisible && (
                <div className={styles.cardFooter}>
                    <div className={styles.cardFooterItem}>
                        <span>Pages Indexed</span>
                        <span>{documentCount}</span>
                    </div>

                    <div className={styles.cardFooterItem}>
                        <span>Last Updated</span>
                        <span>{new Date(updatedAt).toLocaleDateString()}</span>
                    </div>
                </div>
            )}

            <Popover open={isPopoverOpen} onOpenChange={(e, data) => setIsPopoverOpen(data.open)} positioning={"after-bottom"}>
                <PopoverTrigger>
                    <div> {/* Wrap the children inside a parent element */}
                        {/* Empty div as trigger */}
                    </div>
                </PopoverTrigger>
                <PopoverSurface className={styles.popoverSurface}>
                    <div className={styles.cardPopover}>
                        <Button onClick={() => console.log('Edit clicked')} appearance={"primary"} size={"medium"} shape={"rounded"}>Edit</Button>
                        <Button onClick={() => console.log('Upload clicked')} appearance={"primary"} size={"medium"} shape={"rounded"}>Upload</Button>
                    </div>
                    <div className={styles.cardPopover}>
                        <Button onClick={() => console.log('Close clicked')} appearance={"secondary"} size={"medium"} shape={"rounded"}>Close</Button>
                    </div>
                </PopoverSurface>
            </Popover>
        </Card >
    )
}