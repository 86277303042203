import { Button, Subtitle2, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppAbility } from "../../providers/ability.provider";

const useStyles = makeStyles({
    menuItems: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.overflow("auto"),
    },
    menuItem: {
        ...shorthands.padding("8px"),
        ...shorthands.border("1px", "solid", "#ccc"),
        ...shorthands.borderRadius("4px"),
        ...shorthands.margin("4px", "0"),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        columnGap: tokens.spacingHorizontalXS,
    },
    activeMenuItem: {
        backgroundColor: tokens.colorNeutralBackground1Selected,
    },
});

const administrationBasePath = "/administration";

const items = [
    {
        name: "Organization",
        url: `${administrationBasePath}/organization`,
    },
    {
        name: "Knowledge Base",
        url: `${administrationBasePath}/knowledge-base`,
    },
];

export const NavigationItems = () => {
    const styles = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const ability = useAppAbility();

    const filteredItems = items.filter(item => {
        if (item.name === "Organization") {
            return ability.can("write", "organization");
        }
        return true;
    });

    return (
        <div className={styles.menuItems} aria-label="Administration">
            {filteredItems.map((item, index) => {
                const isSelected = location.pathname === item.url;
                return (
                    <Button
                        as="a"
                        aria-selected={isSelected}
                        appearance={isSelected ? "primary" : "secondary"}
                        onClick={() => item.url && navigate(item.url)}
                        className={styles.menuItem}
                        key={index}
                    >
                        {item.name}
                    </Button>
                );
            })}
        </div>
    )
}