import { PureAbility, AbilityClass, AbilityBuilder } from "@casl/ability";
import { User } from "../api";
import { AppRoles } from "./roles";
import { TokenClaims } from "@azure/msal-common";
export type Actions = "read" | "delete" | "manage" | "write";
export type Subjects = "all" | "organization" | "knowledge_bases" | "question_examples" | "ai_prompts" | "knowledge_base_logs" | "administration";

export type AppAbility = PureAbility<[Actions, Subjects]>;
export const AppAbility = PureAbility as AbilityClass<AppAbility>;

export function createAbility() {
    const ability = new AppAbility();
    ability.can = ability.can.bind(ability);
    ability.cannot = ability.cannot.bind(ability);

    return ability;
}

export function updateAbility(ability: AppAbility, currentUser: User | null) {
    const { can, cannot, rules } = new AbilityBuilder(AppAbility);

    const permissions = currentUser?.permissions || [];

    permissions.forEach(permission => {
        // TODO: consider renaming resource to subject
        const { action, resource } = permission;

        can(action as Actions, resource as Subjects);
    });

    ability.update(rules);
}

export function updateAbilityForIdTokenClaims(ability: AppAbility, idTokenClaims: (TokenClaims & { [key: string]: unknown }) | undefined) {
    const { can, cannot, rules } = new AbilityBuilder(AppAbility);

    const roles = (idTokenClaims?.roles || []) as string[];

    if (roles.includes(AppRoles.User)) {
        can("read", "organization");
        can("read", "knowledge_bases");
        can("read", "question_examples");
    }

    if (roles.includes(AppRoles.Admin)) {
        can("manage", "administration");

        can("read", "organization");
        can("read", "knowledge_bases");
        can("read", "question_examples");

        can("read", "knowledge_base_logs");

        can("write", "organization");
        can("write", "knowledge_bases");
        can("write", "question_examples");

        can("delete", "organization");
        can("delete", "knowledge_bases");
        can("delete", "question_examples");

        can("read", "ai_prompts");
    }

    if (roles.includes(AppRoles.AiPromptEngineer)) {
        can("manage", "administration");

        can("read", "organization");
        can("read", "knowledge_bases");
        can("read", "question_examples");

        can("read", "ai_prompts");
        can("write", "ai_prompts");
    }
    
    if (roles.includes(AppRoles.KBTester)) {
        can("manage", "administration")

        can("read", "organization");
        
        can("read", "knowledge_bases");
        can("write", "knowledge_bases");
        
        can("read", "question_examples");
        can("write", "question_examples");
        can("delete", "question_examples");
    }

    if (roles.includes(AppRoles.BusinessAnalyst)) {
        can("manage", "administration");

        can("read", "organization");
        can("read", "knowledge_bases");
        can("read", "question_examples");

        can("read", "knowledge_base_logs");
    }

    if (roles.includes(AppRoles.HID)) {
        can("manage", "administration");

        can("manage", "all");

        can("read", "organization");
        can("read", "knowledge_bases");
        can("read", "question_examples");

        can("read", "knowledge_base_logs");

        can("write", "organization");
        can("write", "knowledge_bases");
        can("write", "question_examples");

        can("delete", "organization");
        can("delete", "knowledge_bases");
        can("delete", "question_examples");

        can("read", "ai_prompts");
        can("write", "ai_prompts");
    }

    ability.update(rules);
}
