import { useEffect, useState } from "react";

import {
    Persona,
    Menu,
    Button,
    MenuButton,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuPopover,
    SkeletonItem
} from "@fluentui/react-components";
import type { PersonaProps } from "@fluentui/react-components";
import { useMsal } from "@azure/msal-react";
import { ArrowExitRegular, PersonAddRegular } from "@fluentui/react-icons";
import { loginRequest } from '../../authConfig';
import styles from './user-menu.module.scss'
import { AzureAdOrganization, getPhotoRequest, requestAzureADOrganization } from "../../api/directories";
import { updateAbilityForIdTokenClaims } from "../../rbac/define-ability";
import { ability } from "../../providers/ability.provider";

type UserMenuProps = Partial<PersonaProps> & {
    className?: string
}

export const UserMenu = (props: UserMenuProps) => {
    const { instance } = useMsal();
    const allAccounts = instance.getAllAccounts();
    const activeAccount = instance.getActiveAccount();


    const [imageUrl, setImageUrl] = useState<string | undefined>();
    const [tenant, setTenant] = useState<AzureAdOrganization | undefined>();
    const [isTenantLoading, setIsTenantLoading] = useState<boolean>(false);

    const getUserPhoto = async () => {
        try {
            const response = await getPhotoRequest();

            const url = window.URL || window.webkitURL;
            // @ts-ignore
            const blobUrl = url.createObjectURL(response.data);
            setImageUrl(blobUrl);
        } catch (error: unknown) {
            setImageUrl(undefined);
        }
    }

    const fetchOrganization = async () => {
        try {
            setIsTenantLoading(true)

            const response = await requestAzureADOrganization();

            const organization = response.data?.value?.[0];

            setTenant(organization)
        } catch {
            setTenant(undefined)
        } finally {
            setIsTenantLoading(false)
        }
    }

    useEffect(() => {
        getUserPhoto()
        fetchOrganization()
    }, [activeAccount?.tenantId])

    const handleLogoutPopup = () => {
        instance
            .logoutPopup({
                // mainWindowRedirectUri: '/', // redirects the top level app after logout
                postLogoutRedirectUri: '/', // redirects the top level app after logout
                account: instance.getActiveAccount(),
            })
            .then(() => updateAbilityForIdTokenClaims(ability, undefined))
            .catch((error) => console.log(error));
    };

    const handleSignIn = () => {
        instance.loginPopup({
            ...loginRequest,
            prompt: "select_account",
        }).catch((error) => console.log(error));
    }

    const filteredAccounts = allAccounts.filter(account => account.homeAccountId !== activeAccount?.homeAccountId);

    const userRoles = (activeAccount?.idTokenClaims?.roles as string[]) || [];
    
    const finalRoles = userRoles.filter(role => role !== "User");

    return (
        <>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                <MenuButton
                        className={styles.trigger}
                        size="small"
                        appearance="subtle"
                        shape="square"
                        aria-label={`${activeAccount?.name} user`}
                    >
                        <Persona
                            className={styles.persona}
                            name={activeAccount?.name}
                            // Display both email (username) and roles in secondary text
                            secondaryText={
                                <>
                                    <span>{activeAccount?.username}</span>
                                    {finalRoles.length > 0 && (
                                        <>
                                            <br/>
                                            <span style={{ float: "left" }}>{finalRoles.join(" - ")}</span>
                                        </>
                                    )}
                                </>
                            }
                            aria-label="User account information"
                            avatar={{
                                image: {
                                    src: imageUrl,
                                },
                            }}
                        />
                    </MenuButton>
                    
                </MenuTrigger>
                    {/* <MenuButton className={styles.triggerButton} icon={<PersonAddRegular />} onClick={handleSignIn}>Sign In With a Different Account</MenuButton>
                    <MenuButton className={styles.triggerButton} icon={<ArrowExitRegular />} onClick={handleLogoutPopup}>Sign out</MenuButton> */}
                

                <MenuPopover>
                    <MenuList>
                        <Button className={styles.triggerButton} appearance="subtle" shape="square" icon={<PersonAddRegular />} onClick={handleSignIn}>Sign In With a Different Account</Button>
                        <Button className={styles.triggerButton} appearance="subtle" shape="square" icon={<ArrowExitRegular />} onClick={handleLogoutPopup}>Sign out</Button>
                        {/* <div className={styles["menu-organization-row"]}>
                            {isTenantLoading ? (
                                <SkeletonItem />
                            ) : (
                                <span>{tenant?.displayName}</span>
                            )}
                        </div>

                        <Persona
                            className={styles.persona}
                            name={activeAccount?.name}
                            secondaryText={activeAccount?.username}
                            aria-label={"User account information"}
                            avatar={{
                                image: {
                                    src: imageUrl,
                                },
                            }}
                            size="large"
                        />

                        {/* {filteredAccounts.length > 0 && (
                            <>
                                <MenuGroup>
                                    <MenuGroupHeader>Accounts</MenuGroupHeader>
                                    {filteredAccounts.map(account => (
                                        <Persona
                                            key={account.localAccountId}
                                            name={account?.name}
                                            secondaryText={account?.username}
                                            aria-label={"User account information"}
                                            presence={{ status: "available" }}
                                            avatar={{
                                                image: {
                                                    src: imageUrl,
                                                },
                                            }}
                                            {...props}
                                        />
                                    ))}
                                </MenuGroup>
                                <MenuDivider />
                            </>
                        )} */}

                        {/*
                        <MenuItem icon={<PersonAddRegular />} onClick={handleSignIn} aria-label="Switch accounts">Sign in with a different account</MenuItem> */}

                    </MenuList>
                </MenuPopover>
            </Menu>
        </>
    );
};

